<template>
  <div>
    <div
      :style="getElementStyle"
      v-show="data.properties.filed_content != 'Hide'"
    >
      <el-row align="middle">
        <el-col
          :span="showLabel && !isDefalutPosq ? 8 : data.description ? 8 : 24"
          v-if="!data.properties.hideLabel"
        >
          <div class="form-group">
            <label
              for="text"
              v-if="hasLabel && !fromRepeatable"
              :style="[getLabelStyles, computedStyles]"
              style="font-weight: bold"
              >{{ label || data.label }}</label
            >
          </div>
        </el-col>
        <el-col
          v-else
          :span="showLabel ? 4 : data.description ? 20 : 24"
          ref="colRef"
          :style="computedStyles"
        >
          <span v-if="hasLabel" class="transparentcol"></span>
        </el-col>
        <el-col
          :span="2"
          v-if="
            data.allow_quick_add &&
            !isView &&
            !fromRepeatable &&
            (isFromDocument || !checkReadonly())
          "
        >
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </el-col>
        <el-col
          :span="
            showLabel && isDefalutPosq ? (data.allow_quick_add ? 19 : 23) : 11
          "
        >
          <div>
            <!-- <el-input :placeholder="data.label" v-model="form[data.key]"></el-input> -->
            <template v-if="!isView">
              <input
                v-if="!isFieldIsCurrency && !isFieldIsTime"
                :placeholder="data.key"
                v-model="form[data.key]"
                :suffix-icon="data.properties.el_icon"
                :readonly="true"
                :disabled="checkReadonly()"
                @change="applyFormRules"
                :style="{ 'text-align': inputTextAlignment, ...getStyle }"
                class="AFE"
              />

              <input
                v-else-if="isFieldIsTime"
                :placeholder="data.key"
                :value="getTimeFormatedValue(form[data.key])"
                :suffix-icon="data.properties.el_icon"
                :readonly="true"
                @change="applyFormRules"
                :style="{ 'text-align': inputTextAlignment, ...getStyle }"
                class="AFE"
              />

              <currency-input
                v-if="isFieldIsCurrency"
                :placeholder="data.placeholder || data.label"
                v-model="form[data.key]"
                :currency="selectedTableField.validations.currency"
                :locale="selectedTableField.validations.locale"
                :suffix-icon="selectedTableField.properties.el_icon"
                class="el-input__inner"
                :disabled="true"
                @change="applyFormRules"
                :style="{ 'text-align': inputTextAlignment }"
              />
            </template>
            <template v-if="isView">
              <span v-if="isFieldIsCurrency">
                {{
                  form[data.key]
                    | toCurrency(selectedTableField.validations.currency)
                }}
              </span>

              <span v-else-if="isFieldIsTime">
                {{
                  form[data.key]
                    | toTimeFormat(selectedTableField.time_result_type)
                }}
              </span>
              <span v-else class="aggValue">
                {{ form[data.key] }}
              </span>
            </template>
          </div>
        </el-col>
      </el-row>
      <!-- IF NOT AN ENTITY VARIABLE THEN ONLY IT SHOULD UPDATED VALUE --->
      <span style="display: none" v-if="!isEntityVariable">{{
        data.aggregate_self_template ? getSelfTemplateValue() : getValue()
      }}</span>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";
import CurrencyInput from "./../../vue-weblineindia-currency-input/src/component";

export default {
  name: "templates-formComponentsExecute-AggregateFunctionExecute",
  mixins: [userPermissionsHelper],
  components: { CurrencyInput },
  props: [
    "data",
    "isInDataTable",
    "label",
    "form",
    "isView",
    "hasLabel",
    "workflowDataId",
    "fieldsData",
    "isEntityVariable",
    "checkhideFields",
    "checkIsDisabled",
    "parentFormData",
    "fromRepeatable",
    "fromEntityViews",
  ],
  computed: {
    ...mapGetters("repeatableTemplates", ["getAllRepeatableTemplates"]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "";
    },
    // Tharuni Start
    getStyle() {
      return `height:${this.data.height - 30}px; font-weight: 400`;
    },
    inputTextAlignment() {
      return this.textAlignment ? this.textAlignment : "left"; // Set a default value if not provided
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size = this.data.styles.font.font_size
          ? this.data.styles.font.font_size
          : "";
        let bold =
          this.data.styles.font.style == 1 || this.data.styles.font.style == 3
            ? "bold"
            : "";
        let italic =
          this.data.styles.font.style == 2 || this.data.styles.font.style == 3
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }

        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
      }
      return borderStyle;
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    isFieldIsCurrency() {
      return this.selectedTableField &&
        this.selectedTableField.validations &&
        this.selectedTableField.validations.currency
        ? true
        : false;
    },
    isFieldIsTime() {
      return this.selectedTableField && this.selectedTableField.time_result_type
        ? true
        : false;
    },
    getTimeFormatedValue() {
      return (value) => {
        // return this.$options.filters.toTimeFormat(
        //   value,
        //   this.selectedTableField.time_result_type
        // );
        return value;
      };
    },

    // Tharuni end
  },
  async mounted() {
    if (!this.data.aggregate_self_template) {
      await this.fetchTemplate();
      await this.fetchTemplatesData();
      await this.getValue();
    } else {
      this.getSelfTemplateValue();
    }

    this.isTableFieldCurrency();
  },
  data() {
    return {
      validations: [],
      subtractedAndSquaredValues: [],
      repeatableTemplatesData: [],
      aggregation_field: {},
      showLabel: true,
      isDefalutPos: true,
      selectedTableField: {},
      textAlignment: "left",
      previousForm: {},
    };
  },
  methods: {
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    updateCurrency() {
      this.form[this.data.key + "_currency"] = this.data.validations.currency;
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
    },

    async fetchTemplate() {
      if (this.data.repeatable_template) {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          this.data.repeatable_template
        );
        if (this.getSingleCompanyTemplate) {
          this.aggregation_field =
            this.getSingleCompanyTemplate.sections[0].fields.find(
              (x) => x._id == this.data.aggregation_field
            );
        }
      }
    },
    fetchSumOfFields() {
      let sum = 0;
      if (this.repeatableTemplatesData && this.repeatableTemplatesData.length) {
        this.repeatableTemplatesData.forEach((element) => {
          let data = element.template_data;
          sum =
            sum +
            Number(
              data[this.aggregation_field.key]
                ? data[this.aggregation_field.key]
                : 0
            );
        });
      }
      return sum;
    },
    fetchSelfTemplateSumOfFields() {
      let sum = 0;
      this.data.selected_fields.forEach((element) => {
        sum = sum + Number(this.form[element.key] ? this.form[element.key] : 0);
      });
      return sum;
    },

    isTableFieldCurrency() {
      let field;

      this.data.selected_fields.forEach((element) => {
        let tableField =
          this.fieldsData &&
          this.fieldsData.find(
            (e) =>
              (e.inputType == "DATA_TABLE" || e.inputType == "ENTITY_TABLE") &&
              e.data_table_columns.find((e) => e.key == element.key)
          );

        let tableColumnField;
        let nonTableField;

        if (tableField) {
          tableColumnField = tableField.data_table_columns.find(
            (e) => e.key == element.key
          );
        } else {
          nonTableField = this.data.selected_fields[0];
        }
        if (tableField && tableColumnField) {
          field = tableColumnField;
        } else {
          field = nonTableField;
        }
      });

      if (field) {
        if (field.input_type == "ENTITY_VARIABLE") {
          this.selectedTableField = field.selected_enity_field_data;
        } else {
          this.selectedTableField = field;
        }
      }

      if (
        this.selectedTableField &&
        this.selectedTableField.inputType == "CURRENCY"
      ) {
        return true;
      } else {
        return false;
      }
    },
    fetchSelfTemplateTableFieldsData() {
      let tableData = [];
      this.data.selected_fields.forEach((element) => {
        let tableField = this.fieldsData.find(
          (e) =>
            (e.inputType == "DATA_TABLE" || e.inputType == "ENTITY_TABLE") &&
            e.data_table_columns.find((e) => e.key == element.key)
        );

        if (tableField) {
          let tableValuesData;
          if (this.data?.data_table_key) {
            tableValuesData = this.parentFormData[tableField.key] || [{}];
            tableValuesData = tableValuesData.filter((e) => !e.newlyAddedData);
            if (tableField?.input_type == "ENTITY_VARIABLE") {
              tableValuesData = tableValuesData.filter(
                (e) => e.parentDataId == this.form["parentDataId"]
              );
            }
          } else {
            tableValuesData = (this.form[tableField.key] || [{}]).filter(
              (e) => !e.newlyAddedData
            );
          }

          const tableValues = tableValuesData.map((currentValue) => {
            let value = Number(currentValue[element.key]) || 0;
            return value;
          });
          tableData = tableData.concat(tableValues);
        }
      });
      return tableData;
    },
    sumOfArray(array) {
      const sumWithInitial = array.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue),
        0
      );
      return sumWithInitial;
    },

    standardDeviationOfArray(arr, usePopulation = false) {
      const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
      return Math.sqrt(
        arr
          .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
          .reduce((acc, val) => acc + val, 0) /
          (arr.length - (usePopulation ? 0 : 1))
      );
    },

    fetchSelfTemplateSumOfTableFields() {
      let tableData = this.fetchSelfTemplateTableFieldsData();
      let sum = this.sumOfArray(tableData);

      return sum;
    },

    fetchSelfTemplateAvgOfTableFields() {
      let tableData = this.fetchSelfTemplateTableFieldsData();
      return this.sumOfArray(tableData) / tableData.length;
    },
    fetchSelfTemplateSDOfTableFields() {
      let tableData = this.fetchSelfTemplateTableFieldsData();
      return this.standardDeviationOfArray(tableData);
    },

    subtractAndSquareTheEachField() {
      let sum = this.fetchSumOfFields();
      let mean = (sum / this.repeatableTemplatesData.length).toFixed(2);
      let subtractedValue = 0;
      this.repeatableTemplatesData.forEach((element) => {
        let data = element.template_data;
        subtractedValue =
          Number(
            data[this.aggregation_field.key]
              ? data[this.aggregation_field.key]
              : 0
          ) - mean;
        element["subtractedAdSquaredValue"] = subtractedValue * subtractedValue;
        subtractedValue = 0;
      });
    },
    async findStandardDeviation() {
      if (
        this.repeatableTemplatesData &&
        this.repeatableTemplatesData.length > 1
      ) {
        await this.subtractAndSquareTheEachField();
        let sum = 0;
        this.repeatableTemplatesData.forEach((element) => {
          sum = sum + Number(element.subtractedAdSquaredValue);
        });
        let variance = (1 / this.repeatableTemplatesData.length) * sum;
        let standardDeviation = Math.sqrt(variance);
        return standardDeviation.toFixed(3);
      } else {
        return 0;
      }
    },
    subtractAndSquareSelfTemplateTheEachField() {
      let sum = this.fetchSelfTemplateSumOfFields();
      let mean = (sum / this.data.selected_fields.length).toFixed(2);
      let subtractedValue = 0;
      this.data.selected_fields.forEach((element) => {
        subtractedValue =
          Number(this.form[element.key] ? this.form[element.key] : 0) - mean;
        element["subtractedAdSquaredValue"] = subtractedValue * subtractedValue;
        subtractedValue = 0;
      });
    },
    findSelfTemplateStandardDeviation() {
      if (this.data.selected_fields && this.data.selected_fields.length > 1) {
        this.subtractAndSquareSelfTemplateTheEachField();
        let sum = 0;
        this.data.selected_fields.forEach((element) => {
          sum = sum + Number(element.subtractedAdSquaredValue);
        });
        let variance = (1 / this.data.selected_fields.length) * sum;
        let standardDeviation = Math.sqrt(variance);
        return standardDeviation.toFixed(3);
      } else {
        return 0;
      }
    },
    async getValue() {
      if (this.isView) {
        return this.form[this.data.key] || 0;
      }
      // let fields = this.data.selected_fields;

      let gotValue = false;
      let finalValue = 0;

      if (this.data.function_type == "Sum") {
        finalValue = this.fetchSumOfFields();
        gotValue = true;
      }

      if (this.data.function_type == "Average") {
        if (
          this.repeatableTemplatesData &&
          this.repeatableTemplatesData.length
        ) {
          let sum = this.fetchSumOfFields();
          finalValue = (sum / this.repeatableTemplatesData.length).toFixed(2);
        }
        gotValue = true;
      }

      if (this.data.function_type == "Standard Deviation") {
        let sd = await this.findStandardDeviation();
        finalValue = sd ? Number(sd) : 0;
        gotValue = true;
      }
      if (gotValue) {
        this.$set(this.form, this.data.key, finalValue);
        return finalValue;
      } else {
        if (this.form[this.data.key]) {
          return this.form[this.data.key];
        } else {
          this.$set(this.form, this.data.key, "");
          return 0;
        }
      }
    },
    getSelfTemplateValue() {
      if (this.isView) {
        return this.form[this.data.key] || 0;
      }
      let currentForm = {};
      this.data.selected_fields.forEach((field) => {
        this.$set(currentForm, field?.key, this.form[field?.key]);
      });
      if (
        JSON.stringify(currentForm) === JSON.stringify(this.previousForm) &&
        this.data?.fields_type == "INDIVIDUAL"
      ) {
        return
      }
      this.data.selected_fields.forEach((field) => {
        this.$set(this.previousForm, field.key, this.form[field.key]);
      });
      let gotValue = false;
      let finalValue = 0;
      if (this.data.function_type == "Sum") {
        if (this.data.fields_type == "TABLE_FIELDS") {
          finalValue = this.fetchSelfTemplateSumOfTableFields();
        } else {
          finalValue = this.fetchSelfTemplateSumOfFields();
        }
        gotValue = true;
      }

      if (this.data.function_type == "Average") {
        if (this.data.selected_fields && this.data.selected_fields.length) {
          if (this.data.fields_type == "TABLE_FIELDS") {
            let avg = this.fetchSelfTemplateAvgOfTableFields();

            finalValue = avg.toFixed(2);
          } else {
            let sum = this.fetchSelfTemplateSumOfFields();
            finalValue = (sum / this.data.selected_fields.length).toFixed(2);
          }
        }
        gotValue = true;
      }

      if (this.data.function_type == "Standard Deviation") {
        if (this.data.fields_type == "TABLE_FIELDS") {
          let sd = this.fetchSelfTemplateSDOfTableFields();
          finalValue = sd ? Number(sd) : 0;
        } else {
          let sd = this.findSelfTemplateStandardDeviation();
          finalValue = sd ? Number(sd) : 0;
        }
        gotValue = true;
      }
      if (gotValue) {
        this.$set(this.form, this.data.key, finalValue);
        return finalValue;
      } else {
        this.$set(this.form, this.data.key, "");
        return 0;
      }
    },
    async fetchTemplatesData() {
      let params = {
        template_id: this.data.repeatable_template,
        workflow_data_id: this.workflowDataId,
        order_by: "updated_at",
        order_type: "DESC",
      };
      // this.loading = true;
      await this.$store.dispatch(
        "repeatableTemplates/getRepeatableTemplates",
        params
      );

      this.repeatableTemplatesData = this.getAllRepeatableTemplates
        ? [...this.getAllRepeatableTemplates.data]
        : [];
    },
  },
  watch: {
    form: {
      async handler(data) {
        this.data.aggregate_self_template
          ? this.getSelfTemplateValue()
          : this.getValue();
        if (data[this.data.key] !== this.fieldvalue && !this.isInDataTable) {
          this.fieldvalue = data[this.data.key];
          this.applyFormRules();
        }
      },
      deep: true,
      immediate: true,
    },
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.textAlignment = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
};
</script>

<style lang="scss" scoped>
.aggValue {
  font-weight: normal;
}

.AFE {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: inherit;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  &:hover {
    border-color: #409eff; /* Change the border color on hover */
  }
  &:focus {
    border-color: #409eff; /* Change the border color when focused */
  }
  &::placeholder {
    color: #ccc; /* Use your desired lighter gray color here */
  }
}

.transparentcol {
  color: white;
  display: inline-block;
  height: 20px;
  opacity: 0;
}
</style>
